// Generated by Framer (eb71917)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/nFAy8p4fOASsyhPbo192/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["tCO00rMzD"];

const variantClassNames = {tCO00rMzD: "framer-v-tzb8b5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "tCO00rMzD", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "tCO00rMzD", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-1fMZY", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-tzb8b5", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"tCO00rMzD"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-8f0o47"} layoutDependency={layoutDependency} layoutId={"kmeQBkCnP"} transition={transition}><motion.div className={"framer-1g26qro-container"} layoutDependency={layoutDependency} layoutId={"yRUEtfu4q-container"} transition={transition}><Ticker alignment={"center"} direction={"bottom"} fadeOptions={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeWidth: 0, overflow: false}} gap={90} height={"100%"} hoverFactor={1} id={"yRUEtfu4q"} layoutId={"yRUEtfu4q"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} sizingOptions={{heightType: true, widthType: true}} slots={[]} speed={100} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-1fMZY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1fMZY .framer-17xg8cc { display: block; }", ".framer-1fMZY .framer-tzb8b5 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-1fMZY .framer-8f0o47 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-1fMZY .framer-1g26qro-container { align-self: stretch; flex: none; height: auto; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1fMZY .framer-tzb8b5, .framer-1fMZY .framer-8f0o47 { gap: 0px; } .framer-1fMZY .framer-tzb8b5 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-1fMZY .framer-tzb8b5 > :first-child { margin-top: 0px; } .framer-1fMZY .framer-tzb8b5 > :last-child { margin-bottom: 0px; } .framer-1fMZY .framer-8f0o47 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-1fMZY .framer-8f0o47 > :first-child { margin-left: 0px; } .framer-1fMZY .framer-8f0o47 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2958
 * @framerIntrinsicWidth 1223
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FramerBjVTscXdc: React.ComponentType<Props> = withCSS(Component, css, "framer-1fMZY") as typeof Component;
export default FramerBjVTscXdc;

FramerBjVTscXdc.displayName = "hero-ticker";

FramerBjVTscXdc.defaultProps = {height: 2958, width: 1223};

addFonts(FramerBjVTscXdc, [...TickerFonts])